<template>
  <div class="height-100">
    <loading v-if="loading"></loading>
    <div v-else>
      <div style="background-color: white; padding: 0 1rem;">
        <location-select ref="LocationSelect" :filmId="hotMovie.filmId" @areaCinemas="areaCinemas"></location-select>
      </div>
      <!-- 导航 -->
      <div v-if="data.length > 0">
        <!-- 订单详情 -->
        <!-- <van-list :finished="finished" finished-text="没有更多了" @load="onLoad"> -->
        <cinema-time-item :list="data" @scrollToAll="scrollToAll" ref="inematime">
        </cinema-time-item>
        <!-- </van-list> -->
      </div>
      <div class="empt" v-else>
        <!-- <img src="@/assets/images/logo_loading.png">
        
        暂无影城 -->
      </div>
    </div>
  </div>
</template>

<script>
import CinemaTimeItem from "../../components/movie/CinemaTimeItem.vue";
import LocationSelect from "../../components/movie/LocationSelect.vue";
export default {
  name: "",
  props: {},
  data() {
    return {

      loading: true,
      finished: false,
      data: [
      ],
      movieName: '',
      hotMovie: {
        regionId: '',
        cardId: '',
        cityCode: '',
        current: 1,
        filmId: '',
        size: 500,
        lat: null,
        lng: null,
      },
      futureCinemaCounts: [],
      selectTime: ''
    };
  },
  components: { CinemaTimeItem, LocationSelect },
  methods: {
    onLoad() {
      this.hotMovie.current = this.hotMovie.current + 1
      this.queryCinemas()
    },
    areaCinemas(val) {
      this.hotMovie.regionId = val
      this.hotMovie.current = 1
      this.queryCinemas()
    },
    // 刷新
    onRefresh() {
      setTimeout(() => {
        this.hotMovie.current = 1
        this.queryCinemas()
      }, 1000);
    },
    //返回
    goback() {
      this.$router.back();
    },

    queryCinemas() {
      this.$api
        .queryFutureFilmsByFilmId(this.hotMovie)
        .then((res) => {
          this.loading = false
          if (res.code == 1) {
            this.$Toast(res.msg);
            return
          }
          if (this.hotMovie.current == 1) {
            this.data = res.data.futureFilms

          } else {
            this.data.push(...res.data.futureFilms)
          }
          if (res.data.futureCinemaCounts.length > 0) {
            this.futureCinemaCounts = res.data.futureCinemaCounts

          }
          let selectindex = 0
          this.data.forEach((item, index) => {
            if (item.showTime == this.selectTime) {
              selectindex = index
            }
          });
          console.log('index   ', selectindex);
          this.selectTime = this.futureCinemaCounts[selectindex].showTime
          this.setLocation()
          this.finished = true


        })
        .catch((res) => {
          this.loading = false
          // this.$Toast(res);
        });
    },
    scrollToAll(index) {
      this.selectTime = this.data[index].showTime
      this.setLocation()
    },
    setLocation() {
      console.log('  time ', this.selectTime);
      let value = null
      this.futureCinemaCounts.forEach(element => {
        if (element.showTime == this.selectTime) {
          value = element.cinemaCountList
        }
      });

      if (value) {
        var timer = setInterval(() => {
          this.$refs.LocationSelect.setDate(value)
          clearInterval(timer);
        }, 300)
      }
      this.data.forEach((item, index) => {
        if (item.showTime == this.selectTime) {
          this.$refs.inematime.setIndex(index)
        }
      });
    },
  },

  //详情页获取参数
  mounted() {
    this.hotMovie.filmId = this.$route.query.filmId
    this.hotMovie.cardId = localStorage.getItem("cardId");
    document.title = this.$route.query.movieName
    this.hotMovie.lat = localStorage.getItem('latitude')
    this.hotMovie.lng = localStorage.getItem('longitude')
    this.hotMovie.cityCode = localStorage.getItem('cityCode')
    this.queryCinemas();
  },
  // inject: ["reload"],
  // beforeRouteEnter(to, from, next) {
  //   if (from.path !== "/CinemasList" && from.path !== "/SelectAdderss") {
  //     next((vm) => {
  //       vm.reload();
  //     });
  //   } else {
  //     next();
  //   }
  // },
  activated() {
  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.empt {
  display: flex;
  flex-direction: column;
  height: 90vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: #000;
}
</style>