import { render, staticRenderFns } from "./Cinema.vue?vue&type=template&id=702f1336&scoped=true&"
import script from "./Cinema.vue?vue&type=script&lang=js&"
export * from "./Cinema.vue?vue&type=script&lang=js&"
import style0 from "./Cinema.vue?vue&type=style&index=0&id=702f1336&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "702f1336",
  null
  
)

export default component.exports